















































































































import Vue from 'vue';
import axios from 'axios';
import { SmartTable, Config, Column, Sorting, PagingOptions } from 'rey-vue-smarttable';
import Color from '@/models/Color';
import { Chrome } from 'vue-color';
// @ts-ignore
import { Alert } from 'rey-vue-common';
import SystemParameterService from '@/services/SystemParameterService';
import AuthenticationService from '@/services/AuthenticationService';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });
export default Vue.extend({
  name: 'colors',
  components: { SmartTable, Chrome, Alert },
  data() {
    return {
      AuthenticationService: AuthenticationService,
      plcSyncEnabled: false,
      alertMessage: null as string | null,
      successMessage: null as string | null,
      successMessageReset: null as number | null,
      hexValidation: new RegExp('#[0-9A-Fa-f]{6}'),
      LoadState,
      smartTableConfig: new Config(
        (row) => row.colorId,
        [
          new Column({
            title: this.$t('views.admin.colors.tableHeaders.colorCode').toString(),
            fieldName: 'colorCode',
            fieldType: 'string',
            editable: () => false,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 50,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 50
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.admin.colors.tableHeaders.colorHex').toString(),
            fieldName: 'hexValue',
            fieldType: 'string',
            editable: () => AuthenticationService.isAuthenticated,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 7,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 7
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.admin.colors.tableHeaders.isSyncToPlcEnabled').toString(),
            fieldName: 'isSyncToPlcEnabled',
            fieldType: 'boolean',
            editable: () => AuthenticationService.isAuthenticated
          }),
          new Column({
            title: this.$t('views.admin.colors.tableHeaders.colorLuminance').toString(),
            fieldName: 'luminance',
            fieldType: 'numeric',
            editable: () => AuthenticationService.isAuthenticated
          })
        ],
        new Sorting('colorCode', 'ascending'),
        new PagingOptions(25)
      ).withEfApiActions(axios, 'Color'),
      selected: undefined as Color | undefined,
      colorLoadState: LoadState.NotStarted
    };
  },

  async mounted() {
    this.plcSyncEnabled = await SystemParameterService.getSystemParameterBool(
      'EnablePlcColorsSynchronization'
    );
  },

  methods: {
    sendToPlc() {
      this.alertMessage = null;
      this.successMessage = null;

      axios
        .post('/api/Colors/sendTableToPlc')
        .then(() => {
          this.alertMessage = null;
          this.successMessage = this.$t('views.admin.colors.sendToPlcSuccess');
        })
        .catch(() => {
          this.alertMessage = this.$t('views.admin.colors.sendToPlcError');
          this.successMessage = null;
        })
        .finally(() => {
          setTimeout(() => {
            this.alertMessage = null;
            this.successMessage = null;
          }, 2500);
        });
    },
    selectImportFile() {
      return document.getElementById('importFileUpload')?.click();
    },
    startUpload(event: Event) {
      this.alertMessage = null;
      this.successMessage = null;
      const target = event.target as HTMLInputElement;
      if (event.target && target && target.files && target.files.length > 0) {
        const file = target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        axios
          .post('/api/Colors/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            if (this.successMessageReset) {
              clearTimeout(this.successMessageReset);
              this.successMessageReset = null;
            }
            this.alertMessage = null;
            this.successMessage = this.$t('views.admin.colors.importSuccess');
            this.successMessageReset = setTimeout(() => (this.successMessage = null), 2500);
            // @ts-ignore
            this.$refs.smartTable.reload();
          })
          .catch(() => {
            this.alertMessage = this.$t('views.admin.colors.importError');
          });
      }
    },
    calculateMissingLuminance() {
      axios
        .put('/api/Colors/luminance-calculation')
        .then(() => {
          if (this.successMessageReset) {
            clearTimeout(this.successMessageReset);
            this.successMessageReset = null;
          }
          this.alertMessage = null;
          this.successMessage = this.$t('views.admin.colors.luminanceCalculationSuccess');
          this.successMessageReset = setTimeout(() => (this.successMessage = null), 2500);
          // @ts-ignore
          this.$refs.smartTable.reload();
        })
        .catch(() => {
          this.alertMessage = this.$t('views.admin.colors.luminanceCalculationError');
        });
    }
  }
});
